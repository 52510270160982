import React from "react";
import styled from "styled-components";
import { useTranslation } from 'contexts/Localization'
import Text from "../../../components/Text/Text";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Button from "../../../components/Button/Button";
import LanguageIcon from "../../../components/Svg/Icons/Language";
import { Language } from "../types";
import MenuButton from "./MenuButton";

interface Props {
  currentLang: string;
  langs: Language[];
  setLang: (lang: Language) => void;
}

const NoteContainer = styled.div`
		flex: none;
    max-width: 300px;
		padding: 8px 4px;
		background-color: ${({ theme }) => theme.nav.background};
		border-top: solid 2px rgba(133, 133, 133, 0.1);
	`;

const LangSelector: React.FC<Props> = ({ currentLang, langs, setLang }) => {
  const { t } = useTranslation()
  return (<Dropdown
    // position="top-right"
    position="bottom"
    target={
      <Button variant="text" startIcon={<LanguageIcon color="#262626" width="24px" />} pl="0px" pr="10px">
        <Text color="#262626">{currentLang?.toUpperCase()}</Text>
      </Button>
    }
  >
    {/*
    <NoteContainer><Text fontSize="10px">{t("The language translations on this website are automated, and may not be perfectly accurate. Please keep this in mind while using this website.")}</Text></NoteContainer>
    */}
    {langs.map((lang) => (
      <MenuButton
        key={lang.locale}
        fullWidth
        onClick={() => setLang(lang)}
        // Safari fix
        style={{ minHeight: "32px", height: "auto" }}
      >
        {lang.language}
      </MenuButton>
    ))}
  </Dropdown>
  );
}

export default React.memo(LangSelector, (prev, next) => prev.currentLang === next.currentLang);
