import React from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { useCakeVault } from 'state/pools/hooks'
import { Pool } from 'state/types'
import { BIG_ZERO } from 'utils/bigNumber'
import { TokenPairImage } from 'components/TokenImage'
import { PoolCategory } from 'config/constants/types'
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance'
import CakeVaultTokenPairImage from '../../CakeVaultCard/CakeVaultTokenPairImage'
import XttVaultTokenPairImage from '../../CakeVaultCard/XttVaultTokenPairImage'
import BaseCell, { CellContent } from './BaseCell'

interface NameCellProps {
  pool: Pool
}

const StyledCell = styled(BaseCell)`
  flex: 5;
  flex-direction: row;
  padding-left: 12px;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex: 1 0 150px;
    padding-left: 32px;
  }
`

const NameCell: React.FC<NameCellProps> = ({ pool }) => {
  const { t } = useTranslation()
  const { isXs, isSm } = useMatchBreakpoints()
  const { sousId, stakingToken, earningToken, userData, isFinished, isAutoVault, minDepositAmount, poolCategory } = pool
  const {
    userData: { userShares },
  } = useCakeVault()
  const hasVaultShares = userShares && userShares.gt(0)

  const stakingTokenSymbol = stakingToken ? stakingToken.symbol : ''
  const earningTokenSymbol = earningToken ? earningToken.symbol : ''

  const stakedBalance = userData?.stakedBalance ? new BigNumber(userData.stakedBalance) : BIG_ZERO
  const isStaked = stakedBalance.gt(0)
  const isManualCakePool = sousId === 0

  const showStakedTag = isAutoVault ? hasVaultShares : isStaked

  const poolCategoryText = `${poolCategory}`
  let title = `${t('Earn')} ${earningTokenSymbol}`
  // let subtitle = `${t('Stake')} ${stakingTokenSymbol}`
  let subtitle = `${t('Earn')} XTT-b20 ${t('Stake').toLocaleLowerCase()} XTT-b20`
  const showSubtitle = sousId !== 0 || (sousId === 0 && !isXs && !isSm)
  const showMinDepositAmount = sousId !== 0

  if (isAutoVault) {
    title = t('Auto XTT-b20')
    subtitle = t('Automatic restaking')
  } else if (isManualCakePool) {
    // title = t('Manual XTT-b20')
    title = t('General')
    subtitle = `${t('Earn')} XTT-b20 ${t('Stake').toLocaleLowerCase()} XTT-b20`
  }

  return (
    <StyledCell role="cell">
      {isAutoVault ? (
        <XttVaultTokenPairImage mr="8px" width={40} height={40} />
      ) : (
        <TokenPairImage primaryToken={earningToken} secondaryToken={stakingToken} mr="8px" width={40} height={40} />
      )}
      <CellContent>
        {poolCategory !== PoolCategory.CORE && (
          <Text bold={!isXs && !isSm} small={isXs || isSm} color="#140e06">
            {poolCategoryText}
          </Text>
        )}

        {showStakedTag && (
          <Text fontSize="12px" bold color={isFinished ? 'failure' : '#140e06'} textTransform="uppercase">
            {t('Staked')}
          </Text>
        )}
        {showMinDepositAmount ? (
          <>
            <Text fontSize="12px" color="#140e06">
              {t('Minimum Stake: ')}
            </Text>
            <Text bold={!isXs && !isSm} small={isXs || isSm} color="#140e06">
              {formatNumber(getBalanceNumber(minDepositAmount), 0, 0)} XTT-b20
            </Text>
            {/*
            {showSubtitle && (
              <Text fontSize="12px" color="#140e06">
                {subtitle}
              </Text>
            )}
            */}
          </>
        ) : (
          <>
            <Text bold={!isXs && !isSm} small={isXs || isSm} color="#140e06">
              {title}
            </Text>
            {showSubtitle && (
              <Text fontSize="12px" color="#140e06">
                {subtitle}
              </Text>
            )}
          </>
        )}
      </CellContent>
    </StyledCell>
  )
}

export default NameCell
