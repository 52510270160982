import React from "react";
import styled from "styled-components";
import { useTranslation } from 'contexts/Localization'
import { PancakeRoundIcon } from "../../../components/Svg";
import Text from "../../../components/Text/Text";
import Skeleton from "../../../components/Skeleton/Skeleton";

interface Props {
  xttPriceUsd?: number;
}

const PriceLink = styled.a`
  display: flex;
  align-items: center;
  svg {
    transition: transform 0.3s;
  }
  :hover {
    svg {
      transform: scale(1.2);
    }
  }
`;

const StyledImage = styled.img`
  width: 25px;
  margin-right: 8px;
`;

const XttPrice: React.FC<Props> = ({ xttPriceUsd }) => {
  const { t } = useTranslation()
  return xttPriceUsd ? (
    <PriceLink
      href="https://binosaur.finance/swap?outputCurrency=0x70b6c6a555507ee4ac91c15e5c80b7dc8ff3b489"
      target="_blank"
    >
      <StyledImage src="/images/avatar.png" alt="Logo"/>
      <Text color="#1e1e1e" bold>{t('XTT-b20 Price')} {`$${xttPriceUsd.toFixed(3)}`}</Text>
    </PriceLink>
  ) : (
    <Skeleton width={80} height={24} />
  );
};

export default React.memo(XttPrice);
