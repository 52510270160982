import tokens from './tokens'
import { LockerConfig, PoolCategory } from './types'

const lockers: LockerConfig[] = [
  /*
  {
    lid: 1,
    token: tokens.xttbusd,
    stakingToken: tokens.busd,
    earningToken: tokens.xtt,
    contractAddress: {
      97: '0x819A751f93dF195e1891E1d0E1737107aE8EDbeb',
      56: '0xcc8805898381ec2342d19d7221b2414e123afae1',
    },
    sortOrder: 1,
  },
  */
  {
    lid: 2,
    token: tokens.xtt,
    contractAddress: {
      97: '0x79Bf884A12ceF076522Ef60cd05b68576Ab1239E',
      56: '0x0377204bac669551ff6c42d3132665a13c3cb62b',
    },
    sortOrder: 2,
    description: 'Roadmap',
  },
  {
    lid: 3,
    token: tokens.xtt,
    contractAddress: {
      97: '0x79Bf884A12ceF076522Ef60cd05b68576Ab1239E',
      56: '0x3783da5a74e2d524755d4e8f24f9d3d32f7bab61',
    },
    sortOrder: 2,
    description: 'Network Incentive',
  },
  {
    lid: 4,
    token: tokens.xtt,
    contractAddress: {
      97: '0x79Bf884A12ceF076522Ef60cd05b68576Ab1239E',
      56: '0xbe181e5392e4da10c3d92c6b17244d7eb1896e15',
    },
    sortOrder: 2,
    description: 'Forever Locker = Burned',
  },
  {
    lid: 5,
    token: tokens.xtt,
    contractAddress: {
      97: '0x79Bf884A12ceF076522Ef60cd05b68576Ab1239E',
      56: '0x4d480e3fc1e4b8da4b2482d05de48ed03ddd5e24',
    },
    sortOrder: 2,
    description: 'XTTP Subscription Fees',
  },
  {
    lid: 6,
    token: tokens.xtt,
    contractAddress: {
      97: '0x79Bf884A12ceF076522Ef60cd05b68576Ab1239E',
      56: '0x3dfc291dbdedce76436b8b5afc77dfc51666bdfc',
    },
    sortOrder: 2,
    description: 'XTsunami Subscription Fees',
  },
  {
    lid: 7,
    token: tokens.usdt,
    stakingToken: tokens.usdt,
    earningToken: tokens.usdt,
    contractAddress: {
      97: '0x79Bf884A12ceF076522Ef60cd05b68576Ab1239E',
      56: '0xe4e9c2496247372db0a728e6b252ee1ead044297',
    },
    sortOrder: 2,
    description: 'Donate The Team (USDT)',
  },
]

export default lockers
