import React from 'react'
import styled from 'styled-components'
import { Flex, Text } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import Footer from 'components/Menu/Footer'
import SubNav from 'components/Menu/SubNav'

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 16px;
  padding-bottom: 100px;
  min-height: calc(100vh - 64px);
  // background: ${({ theme }) => theme.colors.gradients.bubblegum};
  background: linear-gradient(180deg, #ffffff 22%, #d7caec 100%);

  ${({ theme }) => theme.mediaQueries.xs} {
    background-size: auto;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 24px;
    padding-bottom: 100px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 32px;
    min-height: calc(100vh - 64px);
  }
`

const Page: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  const { t } = useTranslation()
  return (
    <StyledPage {...props}>
      <SubNav />

      <Text
        color="textSubtle"
        fontSize="24px"
        style={{
          textTransform: 'uppercase',
        }}
      >
        {t('Interacting with PancakeSwap smart contracts')}
      </Text>

      <a
        href="https://pancakeswap.finance/swap?outputCurrency=0x70B6C6A555507EE4ac91C15E5c80b7dc8FF3b489"
        target="_blank"
        rel="noreferrer"
      >
        <Text
          color="textSubtle"
          fontSize="20px"
          style={{
            textDecoration: 'underline',
          }}
        >
          {t('https://pancakeswap.finance/swap')}
        </Text>
      </a>
      {children}
      <Flex flexGrow={1} />
      <Footer />
    </StyledPage>
  )
}

export default Page
