import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Binosaur',
  description:
    // 'The most popular AMM on BSC by user count! Earn CAKE through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by PancakeSwap), NFTs, and more, on a platform you can trust.',
    'Earn XTT-b20 through yield farming, then stake it in our Pools to earn more tokens! NFTs, and more, on a platform you can trust.',
  // image: 'https://pancakeswap.finance/images/hero.png',
  image: 'https://binosaur.finance/images/avatar.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Home')} | ${t('Binosaur')}`,
      }
    case '/lockers':
      return {
        title: `${t('Lockers')} | ${t('Binosaur')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('Binosaur')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('Binosaur')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('Binosaur')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('Binosaur')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('Binosaur')}`,
      }
    case '/collectibles':
      return {
        title: `${t('Collectibles')} | ${t('Binosaur')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('Binosaur')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('Binosaur')}`,
      }
    case '/profile/tasks':
      return {
        title: `${t('Task Center')} | ${t('Binosaur')}`,
      }
    case '/profile':
      return {
        title: `${t('Your Profile')} | ${t('Binosaur')}`,
      }
    default:
      return null
  }
}
